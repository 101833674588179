import React from 'react';

const ContactForm = () => {
    return (
        <section className={'text-with-image-section'} id={`contact-form`}>
            <iframe
                width={`100%`} height={`920px`}
                src="https://cdn.forms-content.sg-form.com/dc39937a-99b8-11ee-8a52-6e0782f96b0d"
            />
        </section>
    );
};

export default ContactForm;
