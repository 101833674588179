import React from 'react';
import {graphql} from "gatsby";
import {componentMap, DataProvider} from "../../contentful/data-provider";
import {Helmet} from "react-helmet";
import ogImage from "../../images/ogimages/home.png";
import Layout from "../../components/layout";
import ContactForm from "../../components/sections/_contact-form";

const TokenizedTreasuries = ({data}) => {
    const dataProvider = new DataProvider(data);
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Tokenized Treasuries',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Tokenized Treasuries',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogImage,
                    },
                    {
                        property: `og:image`,
                        content: ogImage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Tokenized Treasuries</title>
            </Helmet>
            <div className={'team-page'}>
                { dataProvider.getCurrentPageSections().map((section, index) => {
                    return (
                        <>
                            { dataProvider.getElementById(section.id) &&
                                React.createElement(
                                    componentMap[section.type],
                                    {
                                        key: index,
                                        content: dataProvider.getElementById(section.id)
                                    }
                                )}
                        </>
                    );
                }) }
                <ContactForm />
            </div>
        </Layout>
    );
};

export default TokenizedTreasuries;

export const query = graphql`
    query {
        contentfulPage(contentful_id: {eq: "vIyOUnhohP4MjSmRCZ4Zt"}) {
            ... CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ... AllEntryDetails
                }
            }
        }
    }
`;
